@media (max-width: 768px) {
  .table th:nth-child(3),
  .table td:nth-child(3),
  .table th:nth-child(5),
  .table td:nth-child(5),
  .table th:nth-child(1),
  .table td:nth-child(1)
   {
    display: none;
  }
}

@media (max-width: 768px) {
  .table th,
  .table td {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }
}
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit-year-field,
input[type="date"]:focus::-webkit-datetime-edit-month-field,
input[type="date"]:focus::-webkit-datetime-edit-day-field,
input[type="date"]:focus::-webkit-datetime-edit-text {
  color: initial;  /* Or any other color you want for the focused state */
}

.table-data {
  color: primarytext !important ; 
  background-color: #f8fafb !important ;
}