/* src/Timeline.css */
.timeline {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    position: relative;
  }
  
  .timeline-item {
    text-align: center;
    flex: 1;
    position: relative;
  }
  
  .timeline-item svg {
    margin-bottom: 10px;
  }
  
  .timeline-content h4 {
    margin: 5px 0;
    font-size: 1.2rem;
    color: gray;
  }
  
  .timeline-content p {
    margin: 0;
    font-size: 1rem;
  }
  .App {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    padding: 20px;
  }

.table_empty {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #00000047;
}

.empty_svg {
  font-size: 30px;
  color: #00000047;
  margin-top: 10px;
}

.form-error {
  color: #d32f2f;
  font-size: 13px;
  margin-top: 2px;
}

.summary-page_row{
  width: 98%;
}

.calender_submit{
  width: 80%;
  height: 55px;
  background-color: #1F489F !important;
  font-weight: bold !important;
  color: "white",
}