.truncate-hover {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  .truncate-hover:hover {
    max-width: none;
    white-space: nowrap;
    overflow: visible;
    cursor: "pointer";
    background-color: #f8f8f8;
    width: fit-content;
    box-shadow: 0px 1px 4px;
    border-radius: 3px;
    z-index: 1;
    padding: 2px 2px 2px 2px;
  }