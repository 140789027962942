.mHeader.open {
  transform: translateY(-60px);
}
.toggle-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.avatar-container{
  display: flex;
  justify-content:right;
  position:fixed;
  right: 20px;
  top: 10px;
  z-index: 100;
  cursor: auto;
}
.mHeader {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1F489F;
  padding: 10px;
  z-index: 100;
}

ul{
  list-style: none;
}
a{
  text-decoration: none;
}
.org-name, .time {
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.menu-trigger img{
  position: absolute;
  top: 5px;
  right: 50px;
  height: 35px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.dropdown-menu {
display: flex;
position: absolute;
top: 60px;
right: 20px;
background-color: #2ec479;
border-radius: var(border-radius);
padding: 10px 20px;
width: 200px;
z-index: auto;
}

.dropdown-menu.active {
display: block;
}
.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

h3{
width: 100%;
text-align: center;
font-size: 18px;
padding: 20px 0;
font-weight: 500;
color: var(--primary-text-color);
line-height: 1.2rem;
}

h3 span{
font-size: 14px;
color: var(--secondary-text-color);
font-weight: 400;
}

.dropdown-menu ul li{
padding: 10px 0;
border-top: 1px solid rgba(0, 0,0, 0.05);
}

.dropdown-menu ul li:hover a{
color: rgb(212, 33, 9);
cursor: pointer;
}

.dropdownItem{
display: flex;
margin: 10px auto;
}

.dropdownItem img{
max-width: 20px;
margin-right: 10px;
opacity: 1;
transition: var(--speed);
overflow: visible;
}
.row.mHeader{
  transition:justify-content 0.3s ease;
}
.row.mHeader.justidy-content-end{
  justify-content: flex-end;
}
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; 
  background-color: #1F489F;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.logo-img {
  width: 200px; 
  height: auto;
}
