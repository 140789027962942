label {
  display: block;
  margin-bottom: 5px;
  font-size: large;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid;
}

.search-container {
  position: relative;
}

.search {
  padding-right: 30px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* .form {
  position: relative;
  width: "20rem";
} */

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 67%;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: 1.25rem;
  background: none;
  transition: all 160ms ease-in;

}



.form__input:focus {
  border-color: rgb(40, 158, 158);
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: #fff;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: rgb(255, 255, 255);
}


.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
  top: -0.6rem;
  font-size: 0.8rem;
  color: "#6c6a6a";
  left: 1.2rem;
}