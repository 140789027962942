.container1 {
  display: flex;
}

.main {
  width: 100%;
  padding: 15px;
  margin-left: 200px;
  overflow-y: auto;
  transition: margin-left 0.5s ease, width 0.5s ease;
}

.sidebar {
  background: #3667c6;
  color: #fff;
  height: 100vh;
  width: 200px;
  position: fixed;
  z-index: 101;
  /* overflow-y: auto; */
  padding-top: 5px;
  transition: width 0.5s ease;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: auto;
}

.link {
  display: flex;
  color: #fff;
  padding: 7px 15px;
  gap: 15px;
  transition: all 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  background: #393e46;
  color: #fff;
  transition: all 0.5s;
}

.active {
  background: #2a509a;
  color: #fff;
}

.link_text,
.icon {
  display: flex;
  align-items: center;
}

.link_text {
  font-size: 15px;
  position: relative;
  top: 0;
}

.icon {
  font-size: 20px;
}

.hidden-scrollbar {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}