.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.pagination li.active {
  font-weight: bold;
  pointer-events: none;
}

.truncate-hover {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.truncate-hover:hover {
  max-width: none;
  white-space: nowrap;
  overflow: visible;
  cursor: "pointer";
  background-color: #f8f8f8;
  width: fit-content;
  box-shadow: 0px 1px 4px;
  border-radius: 3px;
  z-index: 1;
  padding: 2px 2px 2px 2px;
}

.grid-templet {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
}

.grid-templetcontainer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: 94px
}

@media screen and (min-width: 601px) and (max-width: 1500px) {
  .grid-templet {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
  }

  .grid-templetcontainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: 94px
  }
}

@media screen and (min-width: 501px) and (width: 1500px) {
  .grid-templet {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    width: 100%
  }

  .grid-templetcontainer {
    display: grid;
    grid-template-columns: auto auto auto;

  }
}