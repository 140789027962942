.loader__wrapper {
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid #0f3995;
        width: 30px;
        height: 30px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}