.frame-child4 {
    position: relative;
    border-radius: var(--br-11xs);
    width: 238px;
    height: 56px;
    z-index: 0;
  }

  .vector-group {
    display: flex;
    justify-content: flex-start;
  }
  .vector-group {
    position: absolute;
    top: 4px;
    right: 435px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-3xs);
  }

  .frame-child4 {
    position: relative;
    border-radius: var(--br-11xs);
    width: 150px;
    height: 56px;
    z-index: 0;
  }

  .upload-profile-pic1 {
    position: absolute;
    margin: 0 !important;
    top: 16px;
    left: 13px;
    z-index: 1;
  }