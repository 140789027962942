table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    /* border: 1px solid #2c2626; */
    text-align: left;
    padding: 8px;
  }
  
  tr{
    border-color: inherit;
    border-style: solid;
    /* border-width: 1px !important; */
  }


 